import { useState, useEffect } from 'react'
import { usePagination } from 'hooks'
import {
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom'
import { index } from 'services/algolia'
import { animateScroll } from 'react-scroll'
import { get } from 'lodash'

const useSearch = ({
    keywords='',
    hitsPerPage=20,
    filters,
    facets=[],
    ...props
  }) => {

  const [results, setResults] = useState({})
  const [resources, setResources] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    page,
    nbHits,
    nbPages,
    nbStart,
    nbFinish,
    rangeWithDots
  } = usePagination({ results })

  const [query, setQuery] = useState({
    keywords,
    filters,
    facets,
    page,
    hitsPerPage
  })

  const searchResources = async (query) => {
    let newResources = await searchIndex(query)
    setResources(newResources)
  }

  const loadMoreResources = async q => {
    let moreResources = await searchIndex(q)
    setResources([...resources, ...moreResources])
  }

  const searchIndex = async q => {
    setQuery(q)
    setIsLoading(true)
    let facetFilters = buildFacets(q)
    const results = await index.search(q.keywords, {
      filters: filters,
      facetFilters: facetFilters,
      page: parseInt(q.page)-1,
      hitsPerPage: hitsPerPage,
      distinct: true
    })
    setResults(results)
    setIsLoading(false)
    results.hits.length > 0 ? setIsLoaded(true) : setIsLoaded(false);
    return results.hits
  }


  const handleSearch = () => {
    let q = {
      ...query,
      page: 1,
    }
    searchResources(q)
  }

  const handleClear = () => {
    setResources([])
    setIsLoaded(false)
  }

  const buildFacets = (q) => {
    let facets = []
    if(q.facets && q.facets.length > 0)
      q.facets.map(facet => facets.push(`${facet.name}:${facet.value}`));
    return facets
  }

  const handleRefine = (page) => {
    let q = {
      ...query,
      page
    }
    searchResources(q)
    animateScroll.scrollToTop(500)
  }

  const handleLoadMore = (page) => {
    let q = {
      ...query,
      page
    }
    loadMoreResources(q)
  }


  const handleClearSearch = () => {
    let q = {
      ...query,
      keywords: '',
      page: 1
    }
    searchResources(q)
    setIsLoaded(false)
  }

  const toggleFacet = (facet) => {
    if(query.facets.find(f => f.value == facet.value)){
      removeFacet(facet)
    }else{
      selectFacet(facet)
    }
    searchResources(query)
  }

  const selectFacet = (facet) => {
    if(!query.facets.find(f => f.value == facet.value))
      query.facets.push(facet);
    let newQuery = {
      ...query,
      page: 1
    }
    searchResources(newQuery)
  }

  const removeFacet = (facet) => {
    query.facets = query.facets.filter(f => f.value != facet.value)
    let newQuery = {
      ...query,
      page: 1
    }
    searchResources(newQuery)
  }

  const clearFacet = (facetName) => {
    query.facets = query.facets.filter(f => f.name != facetName )
    searchResources(query)
  }

  const handleChange = ev => {
    const { name } = ev.target
    let isCheckbox = ev.target.type === 'checkbox' ? true : false
    const value = isCheckbox ? ev.target.checked : ev.target.value
    const q = {
      ...query,
      [name]: value,
    }
    setQuery(q)
    if(isCheckbox) searchResources(q);
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') handleSearch();
  }

  useEffect(() => {

    const updateQuery = () => {
      let q = {
        ...query,
        keywords: keywords,
        facets: facets,
        page: 1,
        hitsPerPage: hitsPerPage
      }
      setQuery(q)
    }
    updateQuery()
  }, [keywords])


  return({
      page,
      isLoaded,
      isLoading,
      keywords: query.keywords,
      resources,
      nbHits,
      nbPages,
      nbStart,
      nbFinish,
      query,
      setQuery,
      setResources,
      searchDocuments: searchResources,
      handleSearch,
      toggleFacet,
      selectFacet,
      removeFacet,
      clearFacet,
      handleChange,
      handleClear,
      handleClearSearch,
      handleKeyPress,
      handleRefine,
      handleLoadMore,
      rangeWithDots
  })

}

export default useSearch
