import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
  Hidden,
  Box,
  Badge,
  Link
} from '@material-ui/core'
import {
  Close,
  ExpandLess,
  ExpandMore,
  Search,
  Layers,
  PowerSettingsNew,
  SettingsOutlined
} from '@material-ui/icons'
import {
  useMenu,
} from 'hooks'
import Logo from 'resources/images/logos/logo.svg'
import { makeStyles } from '@material-ui/styles'
import {
  MenuItem,
  OnlineStatusAvatar
} from 'components'
import {
  isMember,
  isAdmin,
} from 'helpers/user'
import { MENU_ITEMS } from 'lib/menu'
import styled from 'styled-components'
import { indigo, green } from '@material-ui/core/colors'
import { primaryDark } from 'theme'
import { get } from 'lodash'
const mobile = require('is-mobile')

const drawerWidth = 240

const styles = {
  icon: {
    color: "#333",
    fontSize: '20px'
  }
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: '14px',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  secondaryIcon: {
    marginLeft: 'auto',
    height: 20,
    width: 20
  }
}));

const MenuIconBox = styled(Box)`
  margin-top: 2px;
`

const StyledClose = styled(Close)`
  color: white;
`

const FullName = styled(Typography)`
  text-align: center;
  padding: 10px 0;
`

const StyledList = styled(List)`
  width: 250px;
`

const Drawer = ({
    open,
    mobile,
    toggleMenu,
    handleClick,
  }) => {

  const classes = useStyles();

  return(
      <SwipeableDrawer
        open={ open }
        variant="temporary"
        onOpen={ toggleMenu }
        onClose={ toggleMenu }>
        <StyledList>
          { MENU_ITEMS.map(({value, text}, idx) => (
            <MenuItem
              key={idx}
              value={value}
              text={ text }
              handleClick={ handleClick }
            />
          ))}
        </StyledList>
    </SwipeableDrawer>
  )
}

export default Drawer
