import React from 'react'
import {
  Typography,
  Button,
  Paper,
  Grid,
  Hidden,
  Avatar,
  Box
} from '@material-ui/core'
import { indigo, blue } from '@material-ui/core/colors'
import styled from 'styled-components'


const Feature = ({ feature }) => (
  <Grid item xs={12} sm={6}>
    <Box display='flex' justifyContent='flex-start' p={1}>
      <Box>
        <Typography variant='h6' color='primary'>
          { feature.title }
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          <p>{ feature.description }</p>
        </Typography>
      </Box>
    </Box>
  </Grid>
)

export default Feature
