import React from 'react'
import {
  Typography,
  Grid,
  Avatar,
  Button,
  Hidden,
  Paper,
  Zoom,
  Box
} from '@material-ui/core'
import Container from 'components/layout/Container'
import Column from 'components/layout/Column'
import Page from 'components/layout/Page'
import { Heading } from 'components'
import TextField from '@material-ui/core/TextField'
import HeroImage from 'components/layout/HeroImage'
import styled from 'styled-components'

const Text = styled(Typography)`
  color: white;
`

const Hero = ({
    image,
    title,
    subtitle,
    button: Button,
    logos: Logos,
    ...props
  }) => (
  <HeroImage image={ image }>
    <Zoom in={true}>
      { title &&
      <Box display='flex' justifyContent='center' my={2}>
        <Text variant='h1'>
          { title && title }
        </Text>
      </Box>
      }
    </Zoom>
    { subtitle &&
      <Box display='flex' justifyContent='center'>
        <Text variant='h4'>
          { subtitle }
        </Text>
      </Box>
    }
    { Button &&
      <Zoom in={true}>
        <Box display='flex' justifyContent='center' p={2}>
          { Button }
        </Box>
      </Zoom>
    }
    { Logos &&
      <Box mt={2}>
        <Logos />
      </Box>
    }
  </HeroImage>
)


export default Hero
