import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { SuspenseSpinner } from 'components'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'
import HttpsRedirect from 'react-https-redirect'
import { AppProvider } from 'context'
import { StylesProvider } from '@material-ui/core/styles'

const rootElement = document.getElementById('root');

ReactDOM.render(
  <HttpsRedirect>
    <Suspense fallback={ <SuspenseSpinner /> }>
      <BrowserRouter>
        <AppProvider>
          <StylesProvider injectFirst>
            <App />
          </StylesProvider>
        </AppProvider>
      </BrowserRouter>
    </Suspense>
  </HttpsRedirect>, rootElement)
