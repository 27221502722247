import React from 'react'
import {
  Avatar,
  Typography,
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
  makeStyles
} from '@material-ui/core'
import styled from 'styled-components'
import Quote from 'resources/images/icons/quote.svg'
import { indigo } from '@material-ui/core/colors'

const StyledCard = styled(Card)`
  box-shadow: none;
  background-color: #F6F7FF;
`
const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  margin-left: 50px;
`

const QouteText = styled(Typography)`
  font-style: italic;
`

const QouteImage = styled('img')`
  margin-right: 10px;
`

const Testimonial = ({ mobile, testimonial }) => {
  const { text, image, author, company } = testimonial
  return(
  <StyledCard>
    <CardContent>
      <Box width='100%' display='flex' justifyContent='flex-start' flexDirection='row' alignItems='flex-start'>
        <Box mx={1}><QouteImage src={ Quote } /></Box>
        <QouteText variant="body2" color="textSecondary">
          { text }
        </QouteText>
      </Box>
    </CardContent>
    <CardHeader
      title={
        <Typography variant='h5' color='textSecondary'>
          {author}
        </Typography>
      }
      subheader={
        <Typography variant='overline' color='textSecondary'>{ company }</Typography>
      }
    />
  </StyledCard>
  )
}


export default Testimonial
