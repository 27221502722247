import React, { lazy } from 'react'
import { Route } from 'react-router'
import { Switch, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'
import pMinDelay from 'p-min-delay'

const DELAY = 200

//Guest
const HomeLanding = lazy(() => import("containers/guest/home/HomeLanding"));
const TrainingLanding = lazy(() => import("containers/guest/training/TrainingLanding"))
const SolutionsLanding = lazy(() => import("containers/guest/solutions/SolutionsLanding"))
const PricingLanding = lazy(() => import('containers/guest/pricing/PricingLanding'))
const PrivacyPolicy = lazy(() => import('containers/guest/static/Privacy'))
const TermsOfService = lazy(() => import('containers/guest/static/Terms'))
const AboutLanding = lazy(() => import('containers/guest/static/About'))
const ContactNew = lazy(() => import('containers/guest/contacts/ContactNew'))
const DocumentList = lazy(() => import("containers/guest/documents/DocumentList"));
const DocumentShow = lazy(() => import("containers/guest/documents/DocumentShow"));
const ProductsLanding = lazy(() => import('containers/guest/products/ProductsLanding'))

const Routes = () => (
  <Switch>
    <Route path="/" component={ HomeLanding } exact />
    <Route path="/training" component={ TrainingLanding } exact />
    <Route path="/solutions" component={ SolutionsLanding } exact />
    <Route path="/content" component={ DocumentList } exact />
    <Route path="/products" component={ ProductsLanding } exact />
    <Route path="/documents/:id" component={ DocumentShow } exact />
    <Route path="/pricing" component={ PricingLanding } />
    <Route path="/contact_us" component={ ContactNew } exact/>
    <Route path="/terms" component={ TermsOfService } exact />
    <Route path="/privacy" component={ PrivacyPolicy } exact />
    <Route path="/about" component={ AboutLanding } exact />

    <Route component={ HomeLanding } />
  </Switch>
)

export default Routes
