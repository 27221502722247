import React, { useState, useEffect } from 'react'
import {
  useAlerts,
  useGuestContacts
} from 'hooks'
import { ChatWidget } from 'components'

const ChatContainer = () => {

  const {
    contact,
    createContact,
    handleChange,
    isValid,
    showValidationErrors
  } = useGuestContacts({})

  const {
    showAlertError,
    showAlertSuccess
  } = useAlerts()

  const [open, setOpen] = useState(false)
  const handleToggleChat = () => setOpen(!open);

  const handleSubmit = async () => {
    if(isValid){
      await createContact(contact)
      showAlertSuccess('Thanks! we will be in touch with you shortly')
      handleToggleChat()
    }else{
      showValidationErrors()
    }
  }

  return(
    <ChatWidget
      title='Contact us'
      label='Contact us'
      open={open}
      toggleChat={ handleToggleChat }
      onSubmit={ handleSubmit }
      chat={ contact }
      handleChange={ handleChange }
    />
  )
}

export default ChatContainer
