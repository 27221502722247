import React from 'react'
import { Zoom, Box } from '@material-ui/core'
import { colors } from '@material-ui/core'
import styled from 'styled-components'
import { primaryDark } from 'theme'

const StyledDivider = styled(Box)`
  border-bottom: 3px solid ${colors.indigo[500]};
  width: 80px;
  margin-top: 10px;
  margin-bottom: 40px;
  ${(({align}) => align === 'left' && 'transform-origin:  0% 50%;')};
`;

const Divider = () => (
  <Zoom in={true}>
    <StyledDivider />
  </Zoom>
)

export default Divider;
