import React from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { indigo } from '@material-ui/core/colors'
import { primaryDark } from 'theme'

const BackgroundImage = styled(Box)`
  width: 100%;
  min-height: 240px;
  background-image: linear-gradient( 181deg, ${primaryDark} 30%, ${indigo[900]} 158.5% );
  ${props => props.image && `background: url(${props.image}) top center no-repeat;`}
  background-size: cover;
`

const HeroImage = ({ image, height = '100%', children }) => (
  <BackgroundImage image={ image } px={5} py={8} height={ height }>
    { children }
  </BackgroundImage>
)

export default HeroImage
