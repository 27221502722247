import React, { useState, useEffect } from 'react'
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from 'react-router-dom'
import axios from 'axios'
import api from 'api'
import { get } from 'lodash'
import queryString from 'query-string'
import {
  useNavigation,
  useLoaders,
  useAlerts
} from 'hooks'

 /* useResource
   A base hook that allows for CRUD operations of a REST API that follows
   standard REST patterns of GET POST PUT and DELETE to create, update, create and
   destroy objects.

   @param id - The resource ID to auto fetch
   @param parentId - The resource of the parent ID when using nested resource routes
   @param url - The API endpoint. The is set dynamically using setEndpoint
   @param resourceName - The name of the resource needed when using POST and PUT
   @param validateFn - The validation function that returns an on object with
          errors, messagse, and isValid boolean
 */

const useResource = ({
    id,
    parentId,
    url='/',
    resourceName,
    validateFn,
    showLoaders=true,
    ...props
  }) => {

  const {
    isLoading,
    showLoading,
    hideLoading
  } = useLoaders()

  const {
    showAlertError,
    showAlertSuccess,
    showAlertWarning
  } = useAlerts()

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()
  const searchParams = queryString.parse(location.search)

  const [perPage, setPerPage] = useState(20)
  const [numPages, setNumPages] = useState(1)
  const [endpoint, setEndpoint] = useState(url)
  const [ready, setReady] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [resourceId, setResourceId] = useState(0)
  const [meta, setMeta] = useState({})
  const [resource, setResource] = useState({id: null})
  const [resources, setResources] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [errors, setErrors] = useState([])
  const [messages, setMessages] = useState([])
  const [query, setQuery] = useState(searchParams)
  const [params, setParams] = useState(useParams())

  const fetch = async (id) => {
    if(!id){
      showAlertError('No ID was provided')
    }
    try{
      setIsLoaded(false)
      setResourceId(id)
      let res = await api.get(`${endpoint}/${id}`)
      setResource(res.data)
      setMeta(res.meta)
      setIsEditing(true)
      setIsLoaded(true)
      hideLoading()
      return res.data
    }catch(e){
      handleErrors(e)
    }
    hideLoading()
  }

  const fetchAll = async (query, page=1) => {
    try{
      setQuery(query)
      setPage(page)
      setIsLoaded(false)
      setParams(queryString.stringify(query))
      let res = await api.get(`${endpoint}`, { params: {
        ...query,
        page: page,
        perPage: perPage
      }})
      setResources(res.data)
      if(res.meta){
        setMeta(res.meta)
        setPage(res.meta.page)
        setNumPages(res.meta.numPages)
        setTotalCount(res.meta.totalCount)
      }
      setIsEmpty(res.data.length > 0 ? false : true)
      setIsLoaded(true)
      hideLoading()
      return res.data
    }catch(e){
      handleErrors(e)
    }
    hideLoading()
  }

  const save = async (resource) => {
    let savedResource;
    if(resource.id){
      savedResource = await update(resource)
    }else{
      savedResource = await create(resource)
    }
    return savedResource
  }

  const create = async (resource) => {
    try{
      let validate = validateFn(resource)
      if(validate.isValid){
        setIsValid(true)
        showLoading()
        let res = await api.post(`${endpoint}`, {
          [resourceName]: resource
        })
        if(res.data && res.data.id){
          setResource(res.data)
          setIsLoaded(true)
          setResourceId(res.data.id)
        }
        hideLoading()
        return res.data
      }else{
        hideLoading()
        setIsValid(false)
        setErrors(validate.errors)
        setMessages(validate.messages)
        validate.messages.map(m => showAlertError(m))
        return false
      }
    }catch(e){
      handleErrors(e)
    }
  }

  const update = async (resource) => {
    setResourceId(resource.id)
    try{
      let validate = validateFn(resource)
      if(validate.isValid){
        setIsValid(true)
        showLoading()
        let res = await api.put(`${endpoint}/${resource.id}`, {
          [resourceName]: resource
        })
        hideLoading()
        return res.data
      }else{
        hideLoading()
        setIsValid(false)
        setErrors(validate.errors)
        setMessages(validate.messages)
        validate.messages.map(m => showAlertError(m))
        return false
      }
    }catch(e){
      handleErrors(e)
    }
  }

  const destroy = async (resource) => {
    try{
      showLoading()
      let res = await api.delete(`${endpoint}/${resource.id}`)
      setResource({data:{}})
      hideLoading()
    }catch(e){
      handleErrors(e)
    }
  }

  const pushURLHistory = (query, page) => {
    let { pathname } = location
    let params = queryString.stringify({ ...query })
    if(page && parseInt(page) > 1)
      params = { ...params, page };

    const searchPath = [
      pathname,
      params
    ].join('?')
    history.push(searchPath)
  }

  const showValidationErrors = () => {
    let validate = validateFn(resource)
    if(!validate.isValid){
      validate.messages.map(m => showAlertError(m))
    }
  }

  const handleChange = (ev) => {
    const { name } = ev.target
    let value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value
    let newResource = {
      ...resource,
      [name]: value
    }
    let validate = validateFn(newResource)
    if(validate.isValid){
      setIsValid(true)
    }else{
      setIsValid(false)
    }
    setResource(newResource)
  }

  const handleChangeQuery = (ev) => {
    const { name } = ev.target
    let value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value
    const newQuery = {
      ...query,
      [name]: value,
    }
    fetchAll(newQuery, page)
  }

  const handleChangePage = (page) => {
    setPage(page)
    fetchAll(query, page)
  }

  const refresh = () => fetch(resourceId)
  const refreshAll = () => fetchAll(query, page)

  const uploadFile = async (file, attributeName) => {
    try{
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      let formData = new FormData()
      formData.append(`${resourceName}[${attributeName}]`, file)
      let res = await api.put(`${endpoint}/${resourceId}`, formData, config)
    }catch(e){
      handleErrors(e)
    }
  }

  const deleteFile = async (type) => {
    showLoading()
    await api.post(`${endpoint}/${resourceId}/delete_file`, {
      type
    })
    hideLoading()
  }

  const handleErrors = (e, msg='An error occurred') => {
    showAlertError(msg)
    hideLoading()
    setIsValid(false)
    setIsLoaded(false)
    console.log("useResource Error:", e)
  }

  useEffect(() => {
    if(id){
      setResourceId(id)
      setIsEditing(true)
    }else{
      setResource({})
      setIsLoaded(true)
    }
    if(props.resource){
      setResource(props.resource)
      setIsLoaded(true)
    }

    if(parentId){
      let newUrl = url.replace(':parent_id', parentId)
      setEndpoint(newUrl)
    }

    setReady(true) //The endpoint is now set and ready for queries

  }, [id, parentId, props.resource, url])

  return {
    resourceId,
    isLoading,
    isLoaded,
    isEditing,
    isEmpty,
    isValid,
    resource,
    save,
    update,
    create,
    destroy,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    refresh,
    resources,
    meta,
    fetch,
    fetchAll,
    refreshAll,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    endpoint,
    setResource,
    setResources,
    setEndpoint,
    showValidationErrors,
    ready,
    setIsLoaded
  }
}

export default useResource
