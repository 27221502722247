import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  IconButton,
  Hidden,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import Logo from 'resources/images/logos/logo-blue.svg'
import styled from 'styled-components'
import moment from 'moment'
import { primaryDark } from 'theme'

const  StyledList = styled(List)`
  color: #8ea6b2;
`

const styles = {
  footer: {
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#111',
    padding: '20px 0'
  },
  container: {
    margin: '0 auto',
    width: '100%',
    padding: '20px 20px 40px 20px',
    maxWidth: '850px'
  },
  logo: {
    width: '160px',
    padding: '12px 0 0 0'
  },
  list: {
    margin: '15px 0',
    padding: 0
  },
  listItem: {
    display: 'inline',
    marginRight: '30px',
    maxWidth: "60px"
  },
  link: {
    color: '#8ea6b2',
    margin: '10px 10px 0 0'
  },
  address: {
    color: '#8ea6b2',
    marginTop: '10px'
  },
  text: {
    color: 'white'
  }
}

const LINKEDIN_URL = 'https://www.linkedin.com/company/skillhire'
const FACEBOOK_URL = "https://www.facebook.com/skillhireNYC"

const Footer = ({ ...props }) => {

  const history = useHistory()

  const handleClick = (path) =>
    history.push(path)


  const handleFacebookClick = () =>
    window.open(FACEBOOK_URL, '_blank');


  const handleLinkedinClick = () =>
    window.open(LINKEDIN_URL, '_blank');

  const currentYear = moment().format('YYYY')

  return (
      <Grid
        container
        spacing={0}
        style={ styles.footer }>
        <div style={ styles.container }>
          <Hidden smUp>
              <Link to='/'>
                <img src={ Logo } style={ styles.logo } />
              </Link>
          </Hidden>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap' width='100%'>
          <Hidden only={['xs']}>
            <Box display='flex' flexDirection='column'>
              <Link to='/'>
                <img src={ Logo } style={ styles.logo } />
              </Link>
              <Typography variant="body2" style={ styles.address }>
                1353 U Street NW, Second Floor, Washington DC 20009
                <br />
                Copyright © { currentYear }
              </Typography>
            </Box>
          </Hidden>
          <Box>
            <StyledList>
              <ListItem
                disableGutters
                button
                dense
                onClick={() => window.open('https://www.bimsystem.co', '_blank')}>
                <ListItemText
                  primary={
                    <Typography variant='caption'>Content Management</Typography>
                  }
                />
              </ListItem>
              <ListItem
                disableGutters
                button
                dense
                onClick={() => handleClick('/content')}>
                <ListItemText
                  primary={
                    <Typography variant='caption'>
                      Family Library
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                disableGutters
                button
                dense
                onClick={() => handleClick('/training')}>
                <ListItemText
                  primary={
                    <Typography variant='caption'>Software Training</Typography>
                  }
                />
              </ListItem>
              <ListItem
                disableGutters
                button
                dense
                onClick={() => handleClick('/solutions')}>
                <ListItemText
                  primary={
                    <Typography variant='caption'>Project Solutions</Typography>
                  }
                />
              </ListItem>

            </StyledList>
          </Box>
          <Box>
            <StyledList>
              <ListItem
                disableGutters
                button
                dense
                onClick={() => handleClick('/contact_us')}>
                <ListItemText
                  primary={
                    <Typography variant='caption'>Contact us</Typography>
                  }
                />
              </ListItem>
              <ListItem
                disableGutters
                button
                dense
                onClick={() => handleClick('/about')}>
                <ListItemText
                  primary={
                    <Typography variant='caption'>ABOUT US</Typography>
                  }
                />
              </ListItem>
            </StyledList>
          </Box>
        </Box>
        </div>
      </Grid>
    )
}

export default Footer
