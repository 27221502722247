import React from 'react'
import {
  PersonOutline,
  Assignment,
  Search,
  People,
  Folder,
  CreditCard,
  Layers,
  CloudCircle,
  Home
} from '@material-ui/icons'

const styles = {
  icon: {
    color: "#333",
    fontSize: '20px'
  }
}

export const MENU_ITEMS =
[
  {value: 'home', text: 'Home'},
  {value: 'cms', text: 'Content Management'},
  {value: 'content', text: 'Family Library'},
  {value: 'training', text: 'Software Training'},
  {value: 'solutions', text: 'Project Solutions'},
  {value: 'contactUs', text: 'Contact us'},
]
