import React from 'react'
import PropTypes from 'prop-types'
import { Box, Avatar } from '@material-ui/core'
import NoImage from 'resources/images/folder.png'
import { InsertDriveFile } from '@material-ui/icons'
import { indigo } from '@material-ui/core/colors'
import styled from 'styled-components'

const SquareImage = styled(Box)`
  ${props => props.imageUrl && `background: url(${props.imageUrl}) top center no-repeat;`}
  width: ${(props => `${props.size}px;`)}
  height: ${(props => `${props.size}px;`)}
  background-size: cover;
  border-radius: 5px;
  border: 2px solid #fff;
`

const StyledBox = styled(Box)`
  background-color: #fff;
  width: ${(props => `${props.size}px;`)}
  height: ${(props => `${props.size}px;`)}
  border-radius: 5px;
  border: 2px solid #fff;
`

const StyledFile = styled(InsertDriveFile)`
  font-size: 52px;
  color: ${indigo[500]};
`

const DocumentImage = ({ imageUrl, size=80 }) => (
  imageUrl ?
   <SquareImage size={ size } imageUrl={ imageUrl }/> :
   <StyledBox size={size} display='flex' justifyContent='center' alignItems='center'>
    <StyledFile />
  </StyledBox>
)

export default DocumentImage
