import React from 'react'
import {
  Box,
  Hidden,
  Grid,
  Typography
} from '@material-ui/core'
import AecomLogo from 'resources/images/logos/AECOM.svg'
import WMALogo from 'resources/images/logos/WMA.svg'
import VCALogo from 'resources/images/logos/VCA.svg'
import SMHLogo from 'resources/images/logos/SMH.svg'
import ISLogo from 'resources/images/logos/IS.svg'
import S3877Logo from 'resources/images/logos/S3877.svg'

import IGLogo from 'resources/images/logos/IG.svg'
import RPLogo from 'resources/images/logos/RP.svg'
import DJALogo from 'resources/images/logos/DJA.svg'
import CALogo from 'resources/images/logos/CALLISONRTKL.svg'


import styled from 'styled-components'

const Logo = styled('img')`
  margin: 10px;
  max-height: 40px;
  max-width: 140px;
  ${props => props.logo ==='aecom' && `height: 20px;`}
  ${props => props.logo ==='S3877' && `height: 24px;`}
`
const styles = {
}

const ClientLogos = () => (
  <Box mt={10}>
  <Grid container spacing={1}>
    <Grid item xs={6} sm={6} md={3}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ AecomLogo } logo='aecom' />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={2}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ WMALogo } />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={2}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ VCALogo } />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={2}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ SMHLogo }  />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={3}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ ISLogo } />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={3}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ S3877Logo } logo='S3877' />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={2}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ IGLogo } />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={2}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ RPLogo } />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={2}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ DJALogo } />
      </Box>
    </Grid>
    <Grid item xs={6} sm={6} md={3}>
      <Box display='flex' justifyContent='center' flexWrap='wrap' alignItems='center'>
        <Logo src={ CALogo } />
      </Box>
    </Grid>
  </Grid>
</Box>
)

export default ClientLogos
