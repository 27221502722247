import React, { useState, useEffect, useContext } from 'react'
import {
  useAuth,
  useMenu,
  useAlerts
} from 'hooks'
import { AppContext } from 'context'
import { useHistory } from 'react-router-dom'
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Hidden
} from '@material-ui/core'
import {
  Menu,
  Close,
  Search,
  Pageview,
  CloudCircle
} from '@material-ui/icons'
import Logo from 'resources/images/logos/logo.svg'
import {
  MenuItem,
  Drawer,
  ProfileAutosuggest,
  Toolbar as SearchToolbar,
  UserAvatar,
  AccountMenu,
  AutocompleteSearch
} from 'components'
import styled from 'styled-components'
import { indigo } from '@material-ui/core/colors'
import { primaryDark } from 'theme'
import { get } from 'lodash'

const MenuBox = styled(Box)`
  margin-top: 2px;
`

const SearchBox = styled(Box)`
  margin-top: 6px;
`

const StyledMenu = styled(Menu)`
  color: white;
`
const StyledClose = styled(Close)`
  color: white;
`

const AppHeader = styled(AppBar)`
  width: 100%;
  background-color: ${primaryDark};
`

const StyledAvatarButton = styled(Button)`
  color: white;
  margin-top: 5px;
`

const UploadIcon = styled(CloudCircle)`
  margin-right: 5px;
  font-size: 32px;
`

const SearchIcon = styled(Search)`
  font-size: 32px;
`

const StyledButton = styled(Button)`
  color: white;
  margin-top: 10px;
  padding: 2px 20px;
`

const Header = ({
    ...props
  }) => {

  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openAccountMenu, setOpenAccountMenu] = useState(false)

  const toggleModal = () => setOpenModal(!openModal)
  const toggleMenu = () => setOpen(!open)
  const closeMenu = () => setOpen(false)

  const {
    open: openMenu,
    anchorEl,
    handleToggleMenu,
    closeMenu: closeAccountMenu
  } = useMenu()

  const { showAlertSuccess } = useAlerts()

  const handleClick = async (action) => {
    closeMenu()
    closeAccountMenu()
    switch(action){
      case 'home':
        return history.push('/');
      case 'content':
        return history.push('/content')
      case 'training':
        return history.push('/training');
      case 'solutions':
        return history.push('/solutions');
      case 'cms':
        window.open('https://www.bimsystem.co')
        return;
      case 'contactUs':
        return history.push('/contact_us')
      default:
        return history.push('/')
    }
  }

  return(
      <>
        <AppHeader position="fixed" elevation={0}>
          <Toolbar variant="dense">
            <Box display='flex' justifyContent='space-between' flexDirection='row' width='100%'>
              <Box display='flex' flexDirection='row' justifyContent='flex-start'>
                <MenuBox>
                  <Hidden only={['md','lg','xl']}>
                    <IconButton
                      onClick={ toggleMenu }
                      color="inherit">
                      { open ? <StyledClose /> : <StyledMenu /> }
                    </IconButton>
                  </Hidden>
                </MenuBox>
                <Button onClick={ (e) => handleClick("home")}>
                  <img src={ Logo } width={ 140 } />
                </Button>
              </Box>
              <Box>
                <>
                  <Hidden only={['sm','xs']}>
                    <StyledButton
                      onClick={(e) => handleClick('cms')}>
                      Content Management
                    </StyledButton>
                    <StyledButton
                      onClick={(e) => handleClick('content')}>
                      Family Library
                    </StyledButton>
                    <StyledButton
                      onClick={(e) => handleClick('training')}>
                      Software Training
                    </StyledButton>
                    <StyledButton
                      onClick={(e) => handleClick('solutions')}>
                      Project Solutions
                    </StyledButton>
                  </Hidden>
                </>
              </Box>
            </Box>
          </Toolbar>
        </AppHeader>
        <Drawer
          open={ open }
          toggleMenu={ toggleMenu }
          handleClick={ handleClick }
        />
      </>
  )
}

export default Header
